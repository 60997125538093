import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from "@material-ui/core";
import {HighlightOff} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import {Field, Form} from "react-final-form";
import {calculator} from "../reports/scorecards";
import {FormHelperEnhancedSelectWrapper} from "../catalogmanager/components/EnhancedSelect";
import {FormHelperEnhancedTextWrapper} from "../catalogmanager/components/EnhancedTextField";
import {FormHelperEnhancedRte} from "../catalogmanager/components/EnhancedRte";
import EnhancedErrorDialog from "../catalogmanager/components/EnhancedErrorDialog";
import {utilCreatePartnerSuspension, utilGetPartnerSuspensions} from "../catalogmanager/utils/utils";
import ContentLoader from "react-content-loader";
import moment from "moment";

export const PartnerPauseDialog = (props) => {
    const {open, setOpen, partnerIds, onPause} = props;
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});
    const [initialValues, setInitialValues] = React.useState(null);

    const checkValues = (values) => {
        if (!values?.reason ?? true) {
            return true;
        }
        if (!values?.start ?? true) {
            return true;
        }
        if (!values?.end ?? true) {
            return true;
        }
        if (!values?.message ?? true) {
            return true;
        }
        if (values.start && values.end && values.start > values.end) {
            return true;
        }

        return false;
    }

    const submit = async (values) => {
        const errors = [];
        setSubmitting(true);

        const requests = partnerIds.map(partnerId => {
            const response = utilCreatePartnerSuspension(partnerId, values.reason, values.message, values.start, values.end);
            if (response.error) {
                errors.push(response.error);
            }
            return response;
        });

        await Promise.all(requests);
        if (errors.length > 0) {
            setError({open: true, errorHeader: "Error", errorMessage: errors.join(", ")});
        }
        else {
            await new Promise(r => setTimeout(r, 750));
            setOpen(false);
            if(onPause) onPause();
        }
        setSubmitting(false);
    }

    useEffect(() => {
        const getExistingSuspension = async () => {
            const response = await utilGetPartnerSuspensions();
            if (response.error) {
                setError({open: true, errorHeader: "Error", errorMessage: response.error});
            } else {
                // eslint-disable-next-line
                const foundPartnerSuspension = response.find(suspension => suspension.PartnerId == partnerIds[0]);

                if(foundPartnerSuspension) {
                    setInitialValues({
                        reason: foundPartnerSuspension.ReasonCode,
                        start: moment(foundPartnerSuspension.StartDate).format("YYYY-MM-DD"),
                        end: moment(foundPartnerSuspension.EndDate).format("YYYY-MM-DD"),
                        message: foundPartnerSuspension.Message
                    });
                }
                else {
                    setInitialValues(null);
                }
            }
        }

        const init = async () => {
            setLoading(true);
            await getExistingSuspension();
            setLoading(false);
        }

        if(partnerIds.length === 1)
            init();
        else {
            setInitialValues(null);
            setLoading(false);
        }

        // eslint-disable-next-line
    }, [open]);

    return (
        <>
            <Dialog open={open} maxWidth={"md"} fullWidth={true}>
                <DialogTitle style={{paddingBottom: "0px"}}>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item container style={{flex: 1, flexDirection: "column"}}>
                            <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                Pause
                            </Typography>
                        </Grid>

                        <IconButton onClick={() => setOpen(false)}>
                            <HighlightOff style={{color: "red"}}/>
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Alert severity="info" style={{marginBottom: '1em'}}>
                        <Typography>
                            Pausing {partnerIds.length > 1 ? "these" : "this"} {partnerIds.length > 1 ? "suppliers" : "supplier"} will zero out their inventory until they are unpaused.
                        </Typography>
                    </Alert>

                    {initialValues !== null && (
                        <Alert severity="info" style={{marginBottom: '1em'}}>
                            <Typography>
                                There is a scheduled pause already for this partner and that information is pre-filled should you want to overwrite it.
                            </Typography>
                        </Alert>
                    )}

                    {loading ? (
                        <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    ) : (
                        <Form onSubmit={submit} initialValues={initialValues} decorators={[calculator]}
                              render={({handleSubmit, pristine, invalid, values}) => {
                                  return (
                                      <form onSubmit={handleSubmit} style={{display: 'flex', width: '100%', gap: "0.5em"}}>
                                          <Grid container>
                                              <Grid container style={{gap: "0.5em"}}>
                                                  <Grid container direction={"column"} style={{flex: 1, gap: "0.5em"}}>
                                                      <Field
                                                          component={FormHelperEnhancedSelectWrapper}
                                                          name='reason'
                                                          label={"Reason for pausing"}
                                                          options={[
                                                              {label: "Business Closure", value: "Business Closure"},
                                                              {label: "Holiday/Seasonal", value: "Holiday/Seasonal"},
                                                              {label: "SLA Compliance", value: "SLA Compliance"},
                                                              {label: "Stale Inventory", value: "Stale Inventory"},
                                                              {label: "Other", value: "Other"}
                                                          ]}
                                                          formControlProps={{style: {flex: 1}}}
                                                          required
                                                      />
                                                      <Field component={FormHelperEnhancedTextWrapper} type='date'
                                                             label='Suspension start date' name='start' required
                                                      />
                                                      <Field component={FormHelperEnhancedTextWrapper} type='date'
                                                             label='Suspension end date' name='end' required
                                                             min={values["start"]}
                                                      />

                                                      {/*Allow Appeal Checkbox*/}
                                                      {/*<Field component={FormHelperEnhancedCheckbox} type='date' label='Allow Appeal' name='allowAppeal' />*/}
                                                  </Grid>

                                                  <Grid container style={{flex: 1}}>
                                                      {/*Message Box*/}
                                                      <Field component={FormHelperEnhancedRte} label='Message'
                                                             name='message' required/>
                                                  </Grid>
                                              </Grid>
                                              <Grid container justify={"flex-end"} style={{margin: "1em 0"}}>
                                                  <Button variant='contained' type='submit'
                                                          disabled={pristine || checkValues(values)} color='primary'>
                                                      Submit
                                                  </Button>
                                              </Grid>
                                          </Grid>
                                      </form>
                                  );
                              }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            <Dialog open={submitting} disableBackdropClick disableEscapeKeyDown>
                <DialogTitle>
                    <Typography style={{fontSize: "22px", fontWeight: 600}}>
                        Submitting Pause Request
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container justify={"center"} alignItems={"center"} direction={"column"}>
                        <CircularProgress/>
                    </Grid>
                </DialogContent>
            </Dialog>

            <EnhancedErrorDialog open={error.open} onClose={() => setError({...error, open: false})}
                                 errorHeader={error.errorHeader} errorMessage={error.errorMessage}/>
        </>
    );
}
